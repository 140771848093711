<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Agregar categoría</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Categorie Image -->
        <template v-if="category.image1">
          <!-- Image Container -->
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img :src="category.image1" alt="img" class="responsive">
          </div>

          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5">
            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
            <vs-button disabled class="mr-4" type="flat" @click="$refs.updateImgInput.click()">Actualizar
              imagen
            </vs-button>
            <vs-button type="flat" color="#999" @click="deleteImage">Eliminar imágen</vs-button>
          </div>
        </template>
        <!-- Name -->
        <vs-input data-vv-as="nombre" @blur="category.name= trimString(category.name)" label="Nombre"
                  v-model="category.name"
                  class="w-full" name="name"
                  v-validate="'required|min:3'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('name')">{{ errors.first('name') }}</span>

        <!-- Slug -->
        <vs-input @blur="category.slug= trimString(category.slug)" label="Slug" v-model="category.slug"
                  class="mt-5 w-full" name="slug"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('slug')">{{ errors.first('slug') }}</span>

        <!-- Description -->
        <vs-input data-vv-as="descripción" @blur="category.description= trimString(category.description)"
                  label="Descripción"
                  v-model="category.description" class="mt-5 w-full" name="description"/>
        <span class="text-danger text-sm"
              v-show="errors.has('description')">{{ errors.first('description') }}</span>

        <!-- Fathers -->
        <div class="mt-5">
          <label class="vs-select--label" for="fathers">Categoría padre</label>
          <v-select id="fathers" label="name" :options="fathers" v-model="category.father"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
        </div>
        <!-- Upload -->
        <div class="upload-img mt-5" v-if="!category.image1">
          <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
          <vs-button @click="$refs.uploadImgInput.click()">Subir imagen</vs-button>
        </div>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from "../../mixins/trimString"
import vSelect from 'vue-select'

const firebase = require("firebase")
// Required for side-effects
require("firebase/firestore")
let db = firebase.firestore()
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {
      }
    },
    fathers: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  mixins: [trimString],
  data() {
    return {
      category: {
        id: null,
        name: null,
        slug: null,
        image1: null,
        imageUpdate: null,
        description: null,
        father: null,
      },
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      deleteImageFlag: false
    }
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return
      this.initValues()
      this.$validator.reset()
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return !this.errors.any()
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    submitData() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.progress = true
          // New category
          db.collection('categories').add({
            name: this.category.name,
            slug: this.category.slug,
            description: this.category.description || '',
            father: this.category.father,
            state: true,
            uid: firebase.auth().currentUser.uid,
            position: 100,
            createdAt: firebase.firestore.FieldValue.serverTimestamp()
          }).then((docRef) => {
            let obj = {
              id: docRef.id,
              name: this.category.name,
              slug: this.category.slug,
              description: this.category.description,
              father: this.category.father,
              state: true
            }
            this.$store.dispatch('categories/addCategory', {category: obj})
            this.progress = false
            this.$vs.notify({
              color: 'success',
              title: 'Categoría',
              text: 'Categoría creada correctamente.'
            })
            this.$emit('closeSidebar')
          }).catch(() => {
            this.progress = false
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
          })
        }
      })
    },
    submitImage() {
      return new Promise((resolve, reject) => {
        let imageName = Math.random().toString(36).substr(1, 20)
        let fileExtension = '.' + this.category.imageUpdate.name.split('.').pop()
        let imageNameFinal = imageName + fileExtension

        let storageRef = firebase.storage().ref(`categories/${imageNameFinal}`)
        storageRef.put(this.category.imageUpdate)
            .then((snapshot) => {
              snapshot.ref.getDownloadURL().then((url) => {
                resolve(url)
              })
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.category.image1 = e.target.result
          this.category.imageUpdate = input.target.files[0]
          this.deleteImageFlag = false
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    initValues() {
      if (this.data.id) return
      this.category.id = null
      this.category.name = null
      this.category.slug = null
      this.category.description = null
      this.category.father = null
    },
    deleteImage() {
      this.category.image1 = null
      this.deleteImageFlag = true
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
